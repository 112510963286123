import React from 'react';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import reducers, { createInitialState } from './src/redux/reducers';
import { isBrowser } from './src/utils';


// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element, props }) => {
  const { path } = props;

  let store = createStore(
    reducers,
    createInitialState(path),
  );

  if (isBrowser) {
    store = createStore(
      reducers,
      createInitialState(path),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    );
  }

  return (
    <Provider store={store}>
      {element}
    </Provider>
  );
};
