import detectBrowserLanguage from 'detect-browser-language';

import { createStore } from 'redux';
import {
  SET_ESTIMATE_DATA,
  SET_HEADER_COLOR,
  SET_IS_ESTIMATE_MENU_OPEN,
  SET_IS_MENU_OPEN,
  SET_IS_OFFER_OPEN,
  SET_LANG,
} from '../actionTypes';
import { isBrowser } from '../../utils';


const getInitialLang = () => {
  if (isBrowser) {
    return detectBrowserLanguage() === 'pl-PL' ? 'pl' : 'en';
  }
  return 'en';
};


export const createInitialState = initialUrl => ({
  isMenuOpen: false,
  isEstimateMenuOpen: false,
  lang: getInitialLang(),
  headerColor: initialUrl !== '/' && initialUrl !== '/offer/' ? 'white' : 'black',
  isOfferOpen: false,
  isMobile: isBrowser ? window.innerWidth <= 768 : false,
  estimate: {
    name: '',
    companyName: '',
    email: '',
    phoneNumber: '',
    service: '',
    budget: '',
    description: '',
  },
});

const reducer = (state, action) => {
  switch (action && action.type) {
    case SET_LANG: {
      return { ...state, lang: action.value };
    }
    case SET_HEADER_COLOR: {
      return { ...state, headerColor: action.value };
    }
    case SET_IS_MENU_OPEN: {
      return { ...state, isMenuOpen: action.value };
    }
    case SET_IS_ESTIMATE_MENU_OPEN: {
      return { ...state, isEstimateMenuOpen: action.value };
    }
    case SET_IS_OFFER_OPEN: {
      return { ...state, isOfferOpen: action.value };
    }
    case SET_ESTIMATE_DATA: {
      return { ...state, estimate: action.add };
    }
    default:
      return state;
  }
};

export default reducer;
